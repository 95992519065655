import axios from "axios";

export class Post {
    link: string = "";
    jetpack_featured_media_url: string = "";
}

export class WpApi {
    static getLastPost = (): Promise<Post> => {
        return new Promise<Post>((resolve, reject) => {
            axios.get("https://blog.upensrl.com/wp-json/wp/v2/posts?per_page=1").then((response) => {
                resolve(response.data[0]);
            }).catch((err) => {
                reject(err);
            });
        });
    }
}