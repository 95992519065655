import { Box, Link, Skeleton, Text, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Post, WpApi } from "../api/wp-api";

interface IPostComponent { }

const PostComponent: React.FC<IPostComponent> = (props: IPostComponent) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [post, setPost] = useState<Post | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        WpApi.getLastPost().then((post) => {
            setLoading(false);
            setPost(post);
        }).catch((err) => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <Skeleton height={280} w="full" />
        );
    } else if (post !== undefined) {
        return (
            <Box shadow={"md"} borderWidth='1px' borderRadius='lg' overflow='hidden' h={{base: 260, lg: "15%"}} w="full">
                {/* @ts-ignore */}
                <Image src={post.jetpack_featured_media_url.split('?')[0]} alt="blog-preview" />
                <Box p='2' textAlign={"center"}>
                    <Link href={post.link} target="_blank">
                        <Text fontSize={"xl"} fontWeight={"extrabold"}>Leggi l'articolo</Text>
                    </Link>
                </Box>
            </Box>);
    } else {
        return (
            <Box borderWidth='1px' borderRadius='lg' overflow='hidden' w="full">
                <Box p='6' textAlign={"center"}>
                    <Text fontSize={"xl"} fontWeight={"bold"}>Impossibile ottenere l'ultimo post</Text>
                </Box>
            </Box>);
    }
}

export default PostComponent;
