import './App.css';
import { Avatar, Box, ChakraProvider, Flex, Text, VStack, HStack, IconButton, Link } from '@chakra-ui/react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import PostComponent from './components/post-component';
import theme from './theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box margin="0 auto"
           maxWidth={{ base: "100vw", lg: "50vw" }}
           transition="0.5s ease-out">
      <Flex alignItems={"center"} p={5} justifyContent={"center"} direction={"column"}>
        <Avatar size={"2xl"} src={"upen_avatar.jpeg"} />
        <Text fontSize={"3xl"} fontWeight={"bold"}>@upensrl</Text>
        <Box marginTop={"5%"} marginBottom={"5%"}>
          <HStack>
            <Text fontSize={{ base: '2xl', lg: '2xl' }} fontFamily={"josefin sans"} fontWeight={"semibold"}>Le ultime</Text>
            <Text fontSize={{ base: '3xl', lg: '3xl' }} fontFamily={"Inkfree"}>dal nostro</Text>
            <Text fontSize={{ base: '2xl', lg: '2xl' }} fontFamily={"montserrat"} fontWeight={800}>Blog!</Text>
          </HStack>
        </Box>
        <PostComponent />
        <Box marginTop="8%">
          <HStack>
            <Text fontSize={"2xl"} fontFamily={"josefin sans"} fontWeight={"semibold"}>I nostri</Text>
            <Text fontSize={"4xl"} fontFamily={"Inkfree"}>link</Text>
            <Text fontSize={"2xl"} fontFamily={"montserrat"} fontWeight={800}>utili</Text>
          </HStack>
        </Box>
        <VStack w="100%" spacing={6} style={{ marginBottom: "5%" }}>
          <Link w="100%" target={"_blank"} href={"https://upensrl.com/"}>
            <Box shadow={"md"} borderWidth='1px' borderRadius='lg' p={2} w="full" textAlign={"center"}>
              <Text fontFamily={"josefin sans"} fontSize="lg" fontWeight={"bold"}>Scopri i nostri servizi</Text>
            </Box>
          </Link>
          <Link w="100%" target={"_blank"} href={"https://blog.upensrl.com/"}>
            <Box shadow={"md"} borderWidth='1px' borderRadius='lg' p={2} w="full" textAlign={"center"}>
              <Text fontFamily={"josefin sans"} fontSize="lg" fontWeight={"bold"}>Leggi tutti i nostri articoli</Text>
            </Box>
          </Link>
          <Link w="100%" target={"_blank"} href={"tel:3923997807"}>
            <Box shadow={"md"} borderWidth='1px' borderRadius='lg' p={2} w="full" textAlign={"center"}>
              <Text fontFamily={"josefin sans"} fontSize="lg" fontWeight={"bold"}>Chiamaci!</Text>
            </Box>
          </Link>
        </VStack>
        {/* <Flex w="100%" direction={"row"} marginBottom={"2%"}>
          <Flex direction={"column"} paddingRight={3} w="50%" alignItems="flex-end" justifyContent="flex-start">
            <Text fontSize={"xs"} fontFamily={"josefin sans"} fontWeight={"bold"}>Via Omobono Tenni 11/A</Text>
            <Text fontSize={"xs"} fontFamily={"josefin sans"} fontWeight={"bold"}>42123, Reggio Emilia (RE)</Text>
          </Flex>
          <Flex direction={"column"} paddingLeft={3} w="50%" alignItems="flex-start" justifyContent="flex-start">
            <Text fontSize={"xs"} fontWeight={"bold"}>+39 0522 246559</Text>
            <Text fontSize={"xs"} fontWeight={"bold"}>hello@upensrl.com</Text>
          </Flex>
        </Flex> */}
        <HStack spacing={4}>
          <Link target="_blank" href="https://it-it.facebook.com/upensrl/">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaFacebookF size={22} />} aria-label={''} />
          </Link>
          <Link target="_blank" href="https://www.instagram.com/upensrl/?hl=it">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaInstagram size={22} />} aria-label={''} />
          </Link>
          <Link target="_blank" href="https://twitter.com/upensrl/">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaTwitter size={22} />} aria-label={''} />
          </Link>
          <Link target="_blank" href="https://wa.me/3923997807">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaWhatsapp size={22} />} aria-label={''} />
          </Link>
          <Link target="_blank" href="https://it.linkedin.com/company/upen">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaLinkedinIn size={22} />} aria-label={''} />
          </Link>
          <Link target="_blank" href="https://pin.it/1VhHRSb">
            <IconButton size={"lg"} variant={"ghost"} icon={<FaPinterestP size={22} />} aria-label={''} />
          </Link>
        </HStack>
      </Flex>
      </Box>
    </ChakraProvider >
  );
}

export default App;
